
// @import url('https://fonts.googleapis.com/css2?family=Original+Surfer&display=swap');

:root {

  --doorboxprimary_bg : #eb950b !important;
  --doorboxprimary_text: #eb950b !important;
  --doorboxprimary_bglight : #fffbf7 !important;
  --doorboxprimary_bgmid : #4a2e13 !important;
  --doorboxprimary_bg2 : #f8ede3f1 !important;
}


.doorbox_gradient_bg {

  background: var(--doorboxprimary_bglight);  /* fallback for old browsers */
background: -webkit-linear-gradient(to left, var(--doorboxprimary_bglight), var(--doorboxprimary_bg2));  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to left, var(--doorboxprimary_bglight), var(--doorboxprimary_bg2)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


}

* {
  font-family: 'Original Surfer', sans-serif !important;
}
.doorbox_text_color_primary {
  color: var(--doorboxprimary_text);
}

#hubspot-messages-iframe-container {
  color-scheme : light;
}


.accordion {

  --bs-accordion-active-bg: none !important;
  outline:none !important;
  border: none !important;
 }


 .accordion-button:not(.collapsed) {
  color: var(--doorboxprimary_bg) !important;
  background-color: var(--bs-accordion-active-bg);
  box-shadow: none !important;
}

.accordion-button::after {
  filter: invert(48%);
  display: none !important;
}

.rotateright45deg {
  transform: rotate(45deg);
}
.rotateright10deg {
  transform: rotate(10deg);
}

.rotateleft10deg {
  transform: rotate(-10deg);
}

.textondarkdeep {
  color:   #212529 !important;
}


.textondarksubtle {
  color:   #565656 !important;
}

.roundedbuttonbig {
  border-radius: 25px !important;
  background-color: var(--doorboxprimary_bg) !important;
  /* color: #212529 !important; */
  border: 1px solid var(--doorboxprimary_bg) !important;

}

.roundedbuttonsmall {
  border-radius: 15px !important;
  /* --bs-btn-padding-y: 3rem !important; */
  --bs-btn-padding-x: 0.7rem !important;
  background-color: var(--doorboxprimary_bg) !important;
  /* color: #212529 !important; */
  border: 1px solid var(--doorboxprimary_bg) !important;
  /* --bs-btn-font-size: 0rem !important; */

}


#toggle-label {
  position: absolute;
  top: 28px ;
  right: 31%;

}

.navbar-toggler {
  box-shadow:  none !important;
}

.accordion-item {
  // border: var(--bs-accordion-border-width) solid #2d353d !important;
  border: none !important;
   background-color: var(--bs-body-bg) !important;
   
  // background: #669fd7 !important;
  margin-bottom: 5px;
}

.swiper-pagination-bullet-active {
  background-color: var(--doorboxprimary_bg) !important;
}

.accordion-button  {
  border-radius: 15px !important;
  background-color: var(--bs-tertiary-bg) !important;
}



@media screen and (max-width: 700px) {
  
  #navbar {

    /* position: fixed !important;
    top: 0px !important;
    width: 100%; */
    
    .navbar-nav {
     margin-bottom: 30px;
      a:hover {
        background-color: var(--bs-tertiary-bg);
        padding-left: 10px;
        border-radius: 20px;
        margin-bottom: 10px;
        text-align: center;
        // transition: all 1s;
      }
    }
    #toggle-label {
      position: absolute !important;
      top: 22px !important;
      right: 23%;
    }
    .imgbox {
      
      border-radius: 10px !important;
      img {
        width: 50px !important;
        height: 50px !important;
      }
    }

  }


  .heroSection {

    .sectionone {

        padding: 0px !important;
        position: relative;
       
        img {

            position: absolute;
            top: -50px !important;
            
        }

        .subtext {
          width: 100% !important;
        }

        #herotextdiv {

          display: flex !important;
          justify-content: center !important;
          flex-direction: column !important;
          padding: 10px;
     
          h1 {
            align-self: center !important;
            font-size: 30px !important;
            text-align: center;
         
          }
          label {
            text-align: center;
          }
  
          button {
            align-self: center !important;
            margin-top: 50px !important;
          }
        }

     
        // background-color: red;
    }

    .sectiontwo {

      position: relative;
      margin-bottom: 50px;

        #mainblob {
          height: 350px !important;
          width: 350px !important;
        }
        .heroimage {
            position: absolute;
            // top: 150px;
            top: 10px;
            height: 350px !important;
            width: 350px !important;
        }
    
    }


}



.howtosection {
   
  .howtobox {
      width: 100% !important;
      margin-top: 50px;

      padding: 10px;
      border-radius: 30px;

      .col-md-4 {
        margin-bottom: 50px ;
      }


      .howtoiconbox {
          width: 55px !important;
   
      }
  
  }
}




.fooddetailsection {

  padding: 10px !important;
  margin: 0px !important;
  margin-top: 100px !important;

  .imgboxsection {

      .imgbox {

          background-color: white;
          width: 100% !important;
          height: 300px;
          border-radius: 20px;

      }

  }

  .textboxsection {

      padding: 50px !important;
      padding-left: 10px !important;
      padding-right: 10px !important;

  }

}



.slidersection {

  margin-top: 100px;
}



.errandetailsection {


  border-radius: 30px;
  padding : 20px !important;
  
  margin: 0px !important;
  margin-top: 100px !important;

  .imgboxsection {

      .imgbox {

          background-color: white;
          width: 100% !important;
          height: 340px !important;
          border-radius: 20px;

      }

  }

  .textboxsection {

      padding: 0px !important;
      margin-top: 50px;
      margin-bottom: 50px;

  }

}





.faqsection {

.accordionbox {

  .accordionbox2 {
      margin-top: 50px ;

  }
}


}



.aboutsection {

  display: flex;
  justify-content: center;
 .aboutbox {

     width: 100% !important;

     padding: 20px !important;

     .aboutimgbox {
         margin-top: 50px ;
         margin-left: 35px;
         margin-bottom: 50px;
         display: flex;
         justify-content: center;
         align-items: center;

         .aboutimage {
             
             align-self: center;
         }

     }
 }
}



.footersection{

  margin-top: 100px !important;
  
  li.mainli {
    margin-bottom: 20px !important;

}

}


}
.heroSection {

  

    .sectionone {

        padding: 0px 100px 0px 0px;
        position: relative;
        img {

            position: absolute;
            top: 30px;

        }

        .subtext {
            width: 80%;
        }
        // background-color: red;
    }

    .sectiontwo {

        position: relative;
       
        // position: absolute;

        .heroimage {
            position: absolute;
            // top: 150px;
            top: 10px;
            height: 80%;
        }
        .heroblob {
            border: 2px solid white;
        }
    }


}



.howtosection {
   
    .howtobox {
        width: 70%;

        background-color: var(--bs-tertiary-bg) ;

        padding: 40px 50px;
        border-radius: 30px;



        .howtoiconbox {
            width: 60px;
            height: 100px;
            border-radius: 30px;
            background-color: var(--doorboxprimary_bg);
            display: flex;
            justify-content: center;
            border: 2px solid white;
        }
    
    }
}


.fooddetailsection {

    background-color: var(--doorboxprimary_bg) ;
    border-radius: 30px;
    padding: 80px 50px;
    
    margin: 100px;

    .imgboxsection {

        .imgbox {

            background-color: white;
            width: 55%;
            height: 350px;
            border-radius: 20px;

        }

    }

    .textboxsection {

        padding-right: 100px;

    }

}



.errandetailsection {

    background-color: var(--doorboxprimary_bg) ;
    border-radius: 30px;
    padding: 80px 50px;
    
    margin: 100px;

    .imgboxsection {

        .imgbox {

            background-color: white;
            width: 55%;
            height: 350px;
            border-radius: 20px;

        }

    }

    .textboxsection {

        padding-right: 100px;

    }

}


.faqsection {

    margin: 100px 0px;



.accordionbox {

    .accordionbox2 {
        
        background-color: var(--bs-tertiary-bg);
        padding: 50px 70px;
        border-radius: 20px;
    }
}


}


.aboutsection {

     display: flex;
     justify-content: center;
    .aboutbox {

        width: 70%;
        background-color: var(--bs-tertiary-bg) ;
        border-radius: 30px;
        padding: 50px;



        .aboutimgbox {
            background-color: rgb(255, 220, 196);
            height: 200px;
            width: 150px;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            .aboutimage {
                
                align-self: center;
            }

        }
    }
}



.footersection{

    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    padding: 100px 0px 10px 0px;
    background-color: var(--doorboxprimary_bg);
    margin-top: 200px;


    .mainlistbox {

         display: flex;
         justify-content: center;
        .mainlist {
            
            li.mainli {
                list-style-type: none;
                display: inline-block;
                margin-right: 100px;
                color: #212529;
            }


            ul {

                 padding-left: 0px;
                li.subli {
                    list-style-type: none;
                    color: #565656;

                    img {
                        
                        border-radius: 10px;
                        margin-top: 10px;
                        border: 1px solid rgb(255, 171, 75);
                    }
                }
              
             
    
                
            }
       
            // padding-left: 200px;
            // padding-right: 200px;
          
        }

        .socials {
      
        }
    }

 
 
}


.pulsateupdown {
    -webkit-animation: action 2s infinite  alternate;
    animation: action 2s infinite  alternate;
}

@-webkit-keyframes action {
    0% { transform: translateY(0); }
    100% { transform: translateY(-30px); }
}

@keyframes action {
    0% { transform: translateY(0); }
    100% { transform: translateY(-30px); }
}


.pulsateupdownslow {
    -webkit-animation: action2 3s infinite  alternate;
    animation: action2 3s infinite  alternate;
}

@-webkit-keyframes action2 {
    0% { transform: translateY(0); }
    100% { transform: translateY(20px); }
}

@keyframes action2 {
    0% { transform: translateY(0); }
    100% { transform: translateY(20px); }
}


.pulsateandtwinckle {

    -webkit-animation: twinkle 1s infinite  alternate;
    animation: twinkle 1s infinite  alternate;

}

@keyframes twinkle {
    0% { transform: translateY(0);
        opacity: 1; }
    100% { transform: translateY(20px);
        opacity: .5; }

  }

  @-webkit-keyframes twinkle {

    0% { transform: translateY(0);
        opacity: 1; }
    100% { transform: translateY(20px);
        opacity: .5; }
}

.hide {
    visibility: hidden !important;
}


.img {
    width: 100%;
    height: 450px;
    object-fit: cover;
    margin: 10px;
    border-radius: 30px;
    cursor: pointer;
  }

  .img:hover {
    opacity: 0.5;
    transition: all 1s;
  }


  .styleSwiper {

    background-color: rgba(118, 118, 118, 0.253);
    padding: 10px;
    border-radius: 35px;
  }
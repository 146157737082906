.headerSection {

    // width: 100%;
    //  padding: 5px 10px;
    // background-color: var(--bs-body-color-rgb)
}

#navbar {

  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 3px 10px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  background-color: var(--bs-body-bg) !important;

}

.imgbox {

  background-color: white;
  border-radius: 12px;
}


// https://codepen.io/Aviral-Chauhan/pen/RwExeaL codepen dark mode
.darkmodecss {


#toggle {
  display: none;
}

// body {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 100%;
//   min-height: 100vh;
//   background-color: #d1d9e7;
//   transition: all 300ms ease;
// }

#toggle-label {
  cursor: pointer;
  outline: transparent;
}

#toggle-div {
  cursor: pointer;
  height: 30px;
  width: 55px;
  background-color: #357bb3;
  border-radius: 100px;
  position: relative;
  overflow: hidden;
  transition: all 300ms ease;
  box-shadow: inset 2px 5px 5px rgba(0, 0, 0, 0.8),
    inset -2px -5px 5px rgba(0, 0, 0, 0.2), 4px 4px 4px rgb(51, 51, 51);
}

.backdrops {
  position: absolute;
  left: 10px;
  top: 10px;
  height: 100%;
  width: 100%;
  transition: all 300ms ease;
}

.backdrop {
  height: 2px;
  width: 2px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 100%;

  position: absolute;
  left: 30%;
  top: 0%;
  transform: translate(-50%, -50%);
  transition: all 300ms ease;
}

.backdrop::after,
.backdrop::before {
  content: "";
  height: 100px;
  width: 100px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  position: absolute;
  transition: all 300ms ease;
}

.backdrop::before {
  left: 1%;
}

.backdrop::after {
  left: 0%;
}

.clouds {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 0%;
  transform: translate(0, -50%);
  transition: all 300ms ease;
}

.cloud {
  position: absolute;
  right: 10%;
  top: 0%;
  background-color: #fbfbfb;
  height: 10px;
  width: 50px;
  border-radius: 0 50px 50px 50px;
  transform: scale(-0.8, 0.8);
}

.cloud::before {
  content: "";
  position: absolute;
  background-color: #fbfbfb;
  height: 20px;
  width: 20px;
  bottom: 18px;
  left: 0;
  border-radius: 100px 100px 0 0px;
}

.cloud::after {
  content: "";
  position: absolute;
  background-color: #fbfbfb;
  height: 40px;
  width: 40px;
  bottom: 18px;
  left: 10px;
  border-radius: 100%;
}

.cloud-1 {
  top: 20%;
  right: 22%;
  transform: scale(-0.6, 0.6);
  opacity: 0.5;
}

.cloud-2 {
  top: 2%;
  right: 40%;
  transform: scale(-0.6, 0.6);
  opacity: 0.5;
}

.cloud-3 {
  top: 92%;
  right: 35%;
}

.cloud-4 {
  top: 85%;
  right: 10%;
  transform: scale(-1, 1);
}

.cloud-5 {
  top: 60%;
  right: -18%;
  transform: rotateZ(-50deg) scale(-1, 1);
}

.sun-moon {
  height: 25px;
  width: 25px;
  background-color: #f1c428;
  border-radius: 100%;
  box-shadow: inset 2px 5px 3px rgba(255, 255, 255, 0.5),
    inset -2px -5px 3px rgba(0, 0, 0, 0.5), 5px 5px 10px rgba(0, 0, 0, 0.5);

  position: absolute;
  left: 5px;
  top: 2px;
  transition: 300ms ease;
}

/* .sun-moon:hover {
  left: 15px;
} */

/* NIGHT */

.stars {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 15%;
  left: 50%;
  transform: translate(0, -50%);
  transition: all 300ms ease;
}

.star {
  position: absolute;
  left: 10%;
  top: 5%;
  height: 6px;
  width: 6px;
  border-radius: 100%;
  background-color: #c4c9d2;
  box-shadow: 0 0 4px #fff;
  animation: twinkle 1s infinite alternate;
}

.star::before {
  content: "";
  position: absolute;
  left: 300%;
  top: 300%;
  height: 4px;
  width: 4px;
  border-radius: 100%;
  background-color: #c4c9d2;
  box-shadow: 0 0 4px #fff;
}

.star::after {
  content: "";
  position: absolute;
  left: 400%;
  bottom: 400%;
  height: 5px;
  width: 5px;
  border-radius: 100%;
  background-color: #c4c9d2;
  box-shadow: 0 0 4px #fff;
}

.star-2 {
  left: 40%;
  top: 10%;
  transform: rotateZ(75deg) scale(1.1);
  animation-delay: 300ms;
}

.star-3 {
  left: 40%;
  top: 60%;
  transform: rotateZ(150deg) scale(0.8);
  animation-delay: 600ms;
}

.crater {
  display: none;
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translate(100%, -10%);
  height: 13px;
  width: 13px;
  background-color: #949eb2;
  border-radius: 100%;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.4);
}

.crater::before {
  content: "";
  height: 5px;
  width: 5px;
  position: absolute;
  top: -10%;
  left: 0%;
  background-color: #949eb2;
  border-radius: 100%;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.4);
}

.crater::after {
  content: "";
  height: 5px;
  width: 5px;
  position: absolute;
  bottom: 0%;
  left: 10%;
  background-color: #949eb2;
  border-radius: 100%;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.4);
}

#toggle-div.night {
  background-color: #1d1f2b;
}

input:checked ~ .clouds {
  top: 150%;
}

input:checked ~ .sun-moon {
  left: calc(100% - 30px);
  background-color: #c4c9d2;
  transform: rotateZ(180deg);
}

/* input:checked ~ .sun-moon:hover {
  left: calc(100% - 115px);
}
 */
input:checked ~ .backdrops {
  left: calc(100% - 10px);
}

input:checked ~ .sun-moon .crater {
  display: block;
}

input:checked ~ .stars {
  top: 0%;
  left: -5px;
}

/* ANIMATIONS */

@keyframes twinkle {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.5;
  }
}

      
}